"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _baseInfo = _interopRequireDefault(require("./components/baseInfo"));
var _gridMenu = _interopRequireDefault(require("./components/gridMenu"));
var _visitChart = _interopRequireDefault(require("./components/visitChart"));
var _userChart = _interopRequireDefault(require("./components/userChart"));
var _userOverview = _interopRequireDefault(require("./components/userOverview"));
var _permission = require("@/utils/permission");
var _authInformation = require("@/api/authInformation");
var _systemConfig = require("@/api/systemConfig");
var _cache = _interopRequireDefault(require("@/plugins/cache"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    baseInfo: _baseInfo.default,
    gridMenu: _gridMenu.default,
    visitChart: _visitChart.default,
    userChart: _userChart.default,
    userOverview: _userOverview.default
  },
  data: function data() {
    return {
      authStatus: null,
      authHost: '',
      authQueryStatus: false,
      notInformation: null
    };
  },
  mounted: function mounted() {
    if (window.location.host.indexOf('localhost') == -1) {
      //this.authStatus = Cache.local.has('auth-information') ? Cache.local.getJSON('auth-information') : null;
      //this.notInformation = Cache.local.has('not-information') ? Cache.local.getJSON('not-information') : null;
      // if(!this.authStatus){
      //   this.getUniq();
      // }
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // authInformationQuery(){
    //   authCertQuery({host:this.authHost}).then(res=>{
    //     let oneDay = 24 * 3600 * 1000;
    //     if(res.data.status == -1 || res.data.status == 2){ 
    //       Cache.local.setItem({name:'not-information',value:true,expires:oneDay})
    //       if(!notInformation){
    //         this.$modal.confirm(res.data.msg).then(()=> {
    //         this.$router.push({path:'/maintain/authCRMEB'})
    //       }).catch(() => {})
    //      }
    //     }else if(res.data.status == 1){
    //       this.$modal.msgSuccess(res.data.msg);
    //       Cache.local.setItem({name:'auth-information',value:true,expires:oneDay * 7})
    //     }else if(res.data.status == 3 || res.data.status == 0){
    //       this.$modal.msg(res.data.msg);
    //     }
    //   })
    // },
    getUniq: function getUniq() {
      var _this = this;
      (0, _systemConfig.configGetUniq)({
        key: 'authHost'
      }).then(function (res) {
        _this.authHost = res;
        if (res !== '') {
          _this.authInformationQuery();
        } else {
          _this.$modal.confirm('您尚未提交授权申请').then(function () {
            _this.$router.push({
              path: '/maintain/authCRMEB'
            });
          });
        }
      });
    }
  }
};