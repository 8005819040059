"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_axios.default.defaults.baseURL = _settingMer.default.httpUrl;
var service = _axios.default.create({
  timeout: 40000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json; charset=UTF-8'
  }
});
service.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  return res;
}, function (error) {});
var _default = exports.default = service;