"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
var bodyClassApi = _interopRequireWildcard(require("@/api/bodyClass"));
var _store = require("@/api/store");
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    return {
      zyypSearchKeywords: "",
      cptjSearchKeywords: "",
      zyypVisible: false,
      cptjVisible: false,
      selectZyypList: [],
      //中药饮片列表
      selectCptjList: [],
      //商品列表
      selectionZyypList: [],
      //选中的中药饮片数据
      selectionCptjList: [],
      //选中的商品数据
      activeIndex: '1',
      //选中导航
      param: {
        rowId: "",
        tzmc: "",
        dingyi: "",
        chengyin: "",
        cjbx: "",
        fbqx: "",
        tztz: "",
        xltz: "",
        dwjhjsynl: "",
        xqjs: "",
        xwbj: "",
        tyfa: "",
        spxx: null,
        productList: [],
        zyypList: []
      },
      editData: {},
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      }
    };
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      bodyClassApi.BodyInfoApi({
        id: this.$route.params.id
      }).then(function (data) {
        _this.editData = data;
        _this.hadlerInitEditData();
      });
    },
    getZyypList: function getZyypList(keywords) {
      var _this2 = this;
      bodyClassApi.zyypListApi(keywords).then(function (res) {
        _this2.selectZyypList = res;
      });
    },
    hadlerInitEditData: function hadlerInitEditData() {
      if (!this.$route.params.id) return;
      var _this$editData = this.editData,
        rowid = _this$editData.rowid,
        tzmc = _this$editData.tzmc,
        dingyi = _this$editData.dingyi,
        chengyin = _this$editData.chengyin,
        cjbx = _this$editData.cjbx,
        fbqx = _this$editData.fbqx,
        tztz = _this$editData.tztz,
        xltz = _this$editData.xltz,
        dwjhjsynl = _this$editData.dwjhjsynl,
        xqjs = _this$editData.xqjs,
        xwbj = _this$editData.xwbj,
        tyfa = _this$editData.tyfa,
        productList = _this$editData.productList,
        zyypList = _this$editData.zyypList;
      this.param.rowId = rowid;
      this.param.tzmc = tzmc;
      this.param.dingyi = dingyi;
      this.param.chengyin = chengyin;
      this.param.cjbx = cjbx;
      this.param.fbqx = fbqx;
      this.param.tztz = tztz;
      this.param.xltz = xltz;
      this.param.dwjhjsynl = dwjhjsynl;
      this.param.xqjs = xqjs;
      this.param.xwbj = xwbj;
      this.param.tyfa = tyfa;
      this.param.productList = productList || [];
      this.param.zyypList = zyypList || [];
    },
    handleSelect: function handleSelect(key) {
      this.activeIndex = key;
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = '编辑体质';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    },
    handleClose: function handleClose(done) {
      this.$confirm('确认关闭？').then(function (_) {
        done();
      }).catch(function (_) {});
    },
    clickZyypSelect: function clickZyypSelect() {
      this.getZyypList();
      this.zyypVisible = true;
    },
    searchZyyp: function searchZyyp() {
      this.getZyypList(this.zyypSearchKeywords);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var arrList = [];
      for (var item in val) {
        var arr = {};
        arr["rowId"] = val[item]['rowid'];
        arr["goodsDescribe"] = val[item]['zuoyong'];
        arr["goodsImageUrl"] = val[item]['logo'];
        arr["goodsName"] = val[item]['spmc'];
        arrList.push(arr);
      }
      this.selectionZyypList = arrList;
    },
    confirmZyyp: function confirmZyyp() {
      this.zyypVisible = false;
      var arrs = [].concat(_toConsumableArray(this.param.zyypList), _toConsumableArray(this.selectionZyypList));
      //根据rowId去重
      var map = new Map();
      var _iterator = _createForOfIteratorHelper(arrs),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (!map.has(item.rowId)) {
            map.set(item.rowId, item);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.param.zyypList = _toConsumableArray(map.values());
    },
    deleteZyyp: function deleteZyyp(rowId) {
      //删除中药饮片的这个元素
      var map = new Map();
      var _iterator2 = _createForOfIteratorHelper(this.param.zyypList),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          if (rowId != item.rowId) {
            map.set(item.rowId, item);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      this.param.zyypList = _toConsumableArray(map.values());
    },
    clickCptjSelect: function clickCptjSelect() {
      this.getCptjList();
      this.cptjVisible = true;
    },
    getCptjList: function getCptjList(keywords) {
      var _this3 = this;
      (0, _store.productLstApi)({
        "type": 1,
        "keywords": keywords
      }).then(function (res) {
        _this3.selectCptjList = res.list;
      });
    },
    handleCptjSelectionChange: function handleCptjSelectionChange(val) {
      this.selectionCptjList = val;
    },
    confirmCptj: function confirmCptj() {
      this.cptjVisible = false;
      var arrs = [].concat(_toConsumableArray(this.param.productList), _toConsumableArray(this.selectionCptjList));
      //根据rowId去重
      var map = new Map();
      var _iterator3 = _createForOfIteratorHelper(arrs),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          if (!map.has(item.id)) {
            map.set(item.id, item);
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      this.param.productList = _toConsumableArray(map.values());
    },
    deleteCptj: function deleteCptj(id) {
      //删除产品推荐的这个元素
      var map = new Map();
      var _iterator4 = _createForOfIteratorHelper(this.param.productList),
        _step4;
      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var item = _step4.value;
          if (id != item.id) {
            map.set(item.id, item);
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
      this.param.productList = _toConsumableArray(map.values());
    },
    handerSubmit: function handerSubmit() {
      var _this4 = this;
      var data = {};
      data.rowId = this.param.rowId;
      data.tzmc = this.param.tzmc;
      data.dingyi = this.param.dingyi;
      data.chengyin = this.param.chengyin;
      data.cjbx = this.param.cjbx;
      data.fbqx = this.param.fbqx;
      data.tztz = this.param.tztz;
      data.xltz = this.param.xltz;
      data.dwjhjsynl = this.param.dwjhjsynl;
      data.xqjs = this.param.xqjs;
      data.xwbj = this.param.xwbj;
      data.tyfa = this.param.tyfa;
      data.productRecommend = [];
      data.zyyp = [];
      if (this.param.productList.length > 0) {
        var productIdList = this.param.productList.map(function (item) {
          return item.id;
        });
        data.productRecommend = productIdList;
      }
      if (this.param.zyypList.length > 0) {
        var zyypRowIdList = this.param.zyypList.map(function (item) {
          return item.rowId;
        });
        data.zyyp = zyypRowIdList;
      }
      bodyClassApi.UpdateBodyApi(data).then(function (res) {
        _this4.$message.success('保存成功');
        _this4.$router.push({
          path: '/content/bodyclass'
        });
      }).catch(function () {
        _this4.$message.error('保存失败');
      });
    },
    searchCptj: function searchCptj() {
      this.getCptjList(this.cptjSearchKeywords);
    }
  }
};