var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(
                                    _vm.tableFrom.dateLimit
                                  )
                                },
                              },
                              model: {
                                value: _vm.tableFrom.dateLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                                },
                                expression: "tableFrom.dateLimit",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入姓名、电话、UID",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keywords", $$v)
                                },
                                expression: "tableFrom.keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.seachList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", width: "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "用户信息",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "spreadCount",
                  label: "推广用户(一级)数量",
                  "sort-method": function (a, b) {
                    return a.spreadCount - b.spreadCount
                  },
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "推广订单数量",
                  prop: "spreadOrderNum",
                  "sort-method": function (a, b) {
                    return a.spreadOrderNum - b.spreadOrderNum
                  },
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "推广订单金额",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.spreadOrderTotalPrice - b.spreadOrderTotalPrice
                  },
                  prop: "spreadOrderTotalPrice",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "佣金总金额",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.totalBrokeragePrice - b.totalBrokeragePrice
                  },
                  prop: "totalBrokeragePrice",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "已提现金额",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.extractCountPrice - b.extractCountPrice
                  },
                  prop: "extractCountPrice",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "已提现次数",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.extractCountNum - b.extractCountNum
                  },
                  prop: "extractCountNum",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "未提现金额",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.brokeragePrice - b.brokeragePrice
                  },
                  prop: "brokeragePrice",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: "冻结中佣金",
                  "min-width": "120",
                  "sort-method": function (a, b) {
                    return a.freezeBrokeragePrice - b.freezeBrokeragePrice
                  },
                  prop: "freezeBrokeragePrice",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "promoterTime",
                  label: "成为推广员时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "spreadNickname",
                  label: "上级推广人",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:retail:spread:list"],
                                expression: "['admin:retail:spread:list']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onSpread(
                                  scope.row.uid,
                                  "man",
                                  "推广人"
                                )
                              },
                            },
                          },
                          [_vm._v("推广人")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi([
                                  "admin:retail:spread:order:list",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onSpreadOrder(
                                              scope.row.uid,
                                              "order",
                                              "推广订单"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("推广订单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.spreadNickname &&
                                scope.row.spreadNickname !== "无"
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "admin:retail:spread:clean",
                                            ],
                                            expression:
                                              "['admin:retail:spread:clean']",
                                          },
                                        ],
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clearSpread(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("清除上级推广人")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleName + "列表",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "100px" } },
                [
                  this.onName !== "man"
                    ? _c(
                        "el-form-item",
                        {
                          key: "1",
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChangeSpread(
                                    _vm.spreadFrom.dateLimit
                                  )
                                },
                              },
                              model: {
                                value: _vm.spreadFrom.dateLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.spreadFrom, "dateLimit", $$v)
                                },
                                expression: "spreadFrom.dateLimit",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTimeSpread },
                            model: {
                              value: _vm.timeValSpread,
                              callback: function ($$v) {
                                _vm.timeValSpread = $$v
                              },
                              expression: "timeValSpread",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          on: { change: _vm.onChanges },
                          model: {
                            value: _vm.spreadFrom.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.spreadFrom, "type", $$v)
                            },
                            expression: "spreadFrom.type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("一级推广人"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("二级推广人"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "关键字：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder:
                              _vm.onName === "order"
                                ? "请输入订单号"
                                : "请输入姓名、电话、UID",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.spreadFrom.nickName,
                            callback: function ($$v) {
                              _vm.$set(_vm.spreadFrom, "nickName", $$v)
                            },
                            expression: "spreadFrom.nickName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: { click: _vm.onChanges },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.onName === "man"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.spreadLoading,
                      expression: "spreadLoading",
                    },
                  ],
                  key: "men",
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.spreadData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "uid", label: "ID", width: "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "头像", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4159822182
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "用户信息",
                      "min-width": "130",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_promoter",
                      label: "是否推广员",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterYesOrNo")(
                                      scope.row.isPromoter
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      62589210
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      label: "推广人数",
                      "min-width": "120",
                      prop: "spreadCount",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      label: "订单数",
                      "min-width": "120",
                      prop: "payCount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onName === "order"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.spreadLoading,
                      expression: "spreadLoading",
                    },
                  ],
                  key: "order",
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.spreadData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "订单ID",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "用户信息", "min-width": "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                [
                                  _vm._v(_vm._s(scope.row.realName)),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _vm._v(_vm._s(scope.row.userPhone)),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1595025101
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "时间",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      label: "状态",
                      "min-width": "100",
                      formatter: _vm.statusFun,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      label: "返佣金额",
                      "min-width": "100",
                      prop: "price",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.spreadFrom.limit,
                  "current-page": _vm.spreadFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.spreadData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeSpread,
                  "current-change": _vm.pageChangeSpread,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }