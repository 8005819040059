"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = loadMonaco;
var _loadScript = require("./loadScript");
var _elementUi = _interopRequireDefault(require("element-ui"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// monaco-editor单例
var monacoEidtor;

/**
 * 动态加载monaco-editor cdn资源
 * @param {Function} cb 回调，必填
 */
function loadMonaco(cb) {
  if (monacoEidtor) {
    cb(monacoEidtor);
    return;
  }
  var vs = 'https://cdn.bootcss.com/monaco-editor/0.18.0/min/vs';

  // 使用element ui实现加载提示
  var loading = _elementUi.default.Loading.service({
    fullscreen: true,
    lock: true,
    text: '编辑器资源初始化中...',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.5)'
  });
  !window.require && (window.require = {});
  !window.require.paths && (window.require.paths = {});
  window.require.paths.vs = vs;
  (0, _loadScript.loadScriptQueue)(["".concat(vs, "/loader.js"), "".concat(vs, "/editor/editor.main.nls.js"), "".concat(vs, "/editor/editor.main.js")], function () {
    loading.close();
    // eslint-disable-next-line no-undef
    monacoEidtor = monaco;
    cb(monacoEidtor);
  });
}