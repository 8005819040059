"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemGroup = require("@/api/systemGroup");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _systemConfig = require("@/api/systemConfig");
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "index",
  data: function data() {
    return {
      iframeUrl: "",
      menuList: [],
      menuInfo: {},
      typeName: "",
      currentPage: "home",
      modelBanner: ["https://image.java.crmeb.net/crmebimage/maintain/2021/07/06/c99ee385e94d4711a0ea4be72169a86euwmzuhxbb2.jpg"],
      urlbg: require('@/assets/imgs/user_bg.png'),
      indexTab: [],
      newIndexTab: [],
      dataList: [],
      addUrlStatus: true,
      infoStatus: false,
      showStatus: false,
      shows: 0,
      indextTabMenu: [],
      newIndextTabMenu: [],
      tabActive: 0,
      cate: false,
      tip: false,
      mockGoods: false,
      mockGoodsImg: require('@/assets/theme/goodsList1.png'),
      showTabNav: true,
      cateArr: [{
        img: require('@/assets/imgs/moren.png'),
        tit: '默认模板'
      }, {
        img: require('@/assets/imgs/youxuan.png'),
        tit: '模板1'
      }, {
        img: require('@/assets/imgs/haowu.png'),
        tit: '模板2'
      }, {
        img: require('@/assets/imgs/shengxian.png'),
        tit: '模板3'
      }],
      cateImg: '',
      active: 1,
      active1: 0,
      disabled: false,
      radio: true,
      logoUrl: '',
      newsInfo: '',
      newNewsInfo: '',
      listActive: 0,
      tabList: ['首页', '分类', '个人中心', '首页NEW'],
      itemIndex: 0
    };
  },
  components: {
    linkaddress: _linkaddress.default
  },
  created: function created() {
    this.iframeUrl = "https://app.beta.java.crmeb.net?type=iframeVisualizing";
  },
  mounted: function mounted() {
    //监听子页面给当前页面传值
    window.addEventListener("message", this.handleMessage, false);
    this.designList();
    this.$set(this, 'typeName', '');
    this.$set(this, 'tip', true);
    this.$nextTick(function () {
      var _this2 = this;
      var clipboard = new _clipboard.default(".copy-data");
      clipboard.on("success", function () {
        _this2.$message.success("复制成功");
      });
    });
    this.getLogo();
    this.kindGet();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 获取logo
    getLogo: function getLogo() {
      var _this3 = this;
      (0, _systemConfig.configInfo)({
        id: 77
      }).then(function (res) {
        _this3.$set(_this3, 'logoUrl', res.mobile_top_logo);
      });
    },
    addBox: function addBox() {
      if (this.menuList.length >= 10 && this.typeName == 'indexMenu') {
        this.$message.warning("设置数据不能超过10条");
      } else if (this.typeName == 'indexTabNav' && this.menuList.length >= 4) {
        this.addUrlStatus = false;
        this.infoStatus = true;
        this.$message.warning("设置数据不能超过4条");
      } else {
        var indexMenu = JSON.parse(JSON.stringify(this.menuList[0]));
        indexMenu.id = null;
        indexMenu.name = "";
        indexMenu.url = "";
        indexMenu.info = "";
        indexMenu.pic = "";
        this.menuList.push(indexMenu);
      }
    },
    // 获取列表值；
    designList: function designList() {
      var _this4 = this;
      (0, _systemGroup.designListApi)().then(function (res) {
        _this4.menuInfo = res;
        var newArr = [];
        var indexMenu = res.indexMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexBanner = res.indexBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        var newIndexMenu = res.newIndexMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var newIndexBanner = res.newIndexBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        var userMenu = res.userMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexNews = res.indexNews.filter(function (item, index, arr) {
          return item.status == true;
        });
        var newIndexNews = res.newIndexNews.filter(function (item, index, arr) {
          return item.status == true;
        });
        var userBanner = res.userBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        newArr.push(indexMenu, indexBanner, userMenu, indexNews, userBanner);
        newArr.push(newIndexMenu, newIndexBanner, newIndexNews);
        _this4.dataList = newArr;
        _this4.$set(_this4, 'newsInfo', indexNews[0] ? indexNews[0].info : '这是一个新闻标题');
        _this4.$set(_this4, 'newNewsInfo', newIndexNews[0] ? newIndexNews[0].info : '这是一个新闻标题');
      });
      (0, _systemGroup.goodDesignList)({
        gid: 70
      }).then(function (response) {
        var list = response.list;
        var arr = [];
        var arr1 = [];
        list.forEach(function (item) {
          var obj = {};
          obj.value = JSON.parse(item.value);
          obj.id = item.id;
          obj.gid = item.gid;
          obj.status = item.status;
          arr.push(obj);
        });
        arr.forEach(function (item1) {
          var obj1 = {};
          obj1.pic = item1.value.fields[0].value;
          obj1.name = item1.value.fields[1].value;
          obj1.info = item1.value.fields[2].value;
          obj1.type = item1.value.fields[3].value;
          obj1.id = item1.id;
          obj1.gid = item1.gid;
          // obj1.show = '1';
          obj1.status = item1.status;
          arr1.push(obj1);
          _this4.indextTabMenu = arr1;
          var indexTab = arr1.filter(function (item, index, arr) {
            return item.status == true;
          });
          _this4.indexTab = indexTab;
        });
        //
      });
      (0, _systemGroup.goodDesignList)({
        gid: 170
      }).then(function (response) {
        var list = response.list;
        var arr = [];
        var arr1 = [];
        list.forEach(function (item) {
          var obj = {};
          obj.value = JSON.parse(item.value);
          obj.id = item.id;
          obj.gid = item.gid;
          obj.status = item.status;
          arr.push(obj);
        });
        arr.forEach(function (item1) {
          var obj1 = {};
          obj1.pic = item1.value.fields[0].value;
          obj1.name = item1.value.fields[1].value;
          obj1.info = item1.value.fields[2].value;
          obj1.type = item1.value.fields[3].value;
          obj1.id = item1.id;
          obj1.gid = item1.gid;
          // obj1.show = '1';
          obj1.status = item1.status;
          arr1.push(obj1);
          _this4.newIndextTabMenu = arr1;
          var newIndexTab = arr1.filter(function (item, index, arr) {
            return item.status == true;
          });
          _this4.newIndexTab = newIndexTab;
        });
        //
      });
    },
    //
    handleMessage: function handleMessage(event) {
      // this.typeName = event.data.name;
      this.typeName = event;
      switch (event) {
        case "indexMenu":
          this.menuList = this.menuInfo.indexMenu;
          this.shows = 3;
          this.mockGoods = false;
          break;
        case "newIndexMenu":
          this.menuList = this.menuInfo.newIndexMenu;
          this.shows = 13;
          this.mockGoods = false;
          break;
        case "indexBanner":
          this.menuList = this.menuInfo.indexBanner;
          this.shows = 1;
          this.mockGoods = false;
          break;
        case "newIndexBanner":
          this.menuList = this.menuInfo.newIndexBanner;
          this.shows = 11;
          this.mockGoods = false;
          break;
        case "userMenu":
          this.menuList = this.menuInfo.userMenu;
          this.shows = 6;
          this.mockGoods = false;
          break;
        case "indexNews":
          this.menuList = this.menuInfo.indexNews;
          this.shows = 2;
          this.mockGoods = false;
          break;
        case "newIndexNews":
          this.menuList = this.menuInfo.newIndexNews;
          this.shows = 12;
          this.mockGoods = false;
          break;
        case "userBanner":
          this.menuList = this.menuInfo.userBanner;
          this.shows = 5;
          this.mockGoods = false;
          break;
        case "indexTabNav":
          this.menuList = this.indextTabMenu;
          this.shows = 4;
          this.mockGoods = false;
          break;
        case "newIndexTabNav":
          this.menuList = this.newIndextTabMenu;
          this.shows = 14;
          this.mockGoods = false;
          break;
        case "goodsMock":
          this.mockGoods = true;
          this.typeName = '';
          this.tip = false;
          this.shows = 7;
          break;
      }
    },
    switchNav: function switchNav(index) {
      this.tabActive = index;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i, boolean) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === "1" && num === "duo") {
          _this.menuList[i].pic = img[0].sattDir;
        }
      }, tit, "content", true);
    },
    // 删除
    bindDelete: function bindDelete(item, index) {
      this.menuList.splice(index, 1);
    },
    saveConfig: (0, _validate.Debounce)(function () {
      switch (this.typeName) {
        case "indexMenu":
          this.saveData("indexMenu", "/admin/page/layout/index/menu/save");
          break;
        case "newIndexMenu":
          this.saveData("newIndexMenu", "/admin/page/layout/newIndex/menu/save");
          break;
        case "indexBanner":
          this.saveData("indexBanner", "/admin/page/layout/index/banner/save");
          break;
        case "newIndexBanner":
          this.saveData("newIndexBanner", "/admin/page/layout/newIndex/banner/save");
          break;
        case "userMenu":
          this.saveData("userMenu", "/admin/page/layout/user/menu/save");
          break;
        case "indexNews":
          this.saveData("indexNews", "/admin/page/layout/index/news/save");
          break;
        case "newIndexNews":
          this.saveData("newIndexNews", "/admin/page/layout/newIndex/news/save");
          break;
        case "userBanner":
          this.saveData("userBanner", "/admin/page/layout/user/banner/save");
          break;
        case "indexTabNav":
          this.saveData("indexTable", "/admin/page/layout/index/table/save");
        case "newIndexTabNav":
          this.saveData("newIndexTable", "/admin/page/layout/newIndex/table/save");
      }
    }),
    saveData: function saveData(param, url) {
      var _this5 = this;
      var tArr = this.menuList.filter(function (item, index, arr) {
        return item.status == true;
      });
      if (this.typeName == 'indexMenu' && tArr.length < 5) {
        this.$message.warning("设置数据不能小于5条");
      } else if (this.typeName == 'indexTabNav' && tArr.length < 2) {
        this.$message.warning("设置数据不能小于2条");
      } else if (this.typeName == 'indexNews' && tArr.length < 1) {
        this.$message.warning("设置数据不能小于1条");
      } else {
        (0, _systemGroup.SaveDataApi)(_defineProperty({}, param, this.changeIndex(this.menuList)), url).then(function (res) {
          _this5.$message.success("保存成功");
          _this5.designList();
        });
      }
    },
    changeIndex: function changeIndex(array) {
      array.map(function (item, index) {
        item.sort = index;
      });
      return array;
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move";
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.menuList);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.menuList = newItems;
    },
    showCurrent: function showCurrent(name) {
      this.currentPage = name;
      this.$set(this, 'typeName', '');
      this.$set(this, 'tip', true);
      this.$set(this, 'cate', false);
    },
    showTip: function showTip() {
      this.$message.warning("暂不支持此操作");
    },
    cateNav: function cateNav() {
      this.currentPage = 'cate';
      this.$set(this, 'typeName', '');
      this.$set(this, 'cate', true);
      this.$set(this, 'mockGoods', false);
      this.getConfig();
    },
    switchTab: function switchTab(index) {
      this.active = index;
      if (this.active == 1) {
        this.cateImg = require('@/assets/imgs/moren.png');
      } else if (this.active == 2) {
        this.cateImg = require('@/assets/imgs/youxuan.png');
      } else if (this.active == 3) {
        this.cateImg = require('@/assets/imgs/haowu.png');
      } else if (this.active == 4) {
        this.cateImg = require('@/assets/imgs/shengxian.png');
      }
    },
    switchKind: function switchKind(index) {
      this.active1 = index;
      switch (index) {
        case '0':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList1.png'));
          this.$set(this, 'showTabNav', true);
          break;
        case '1':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList2.png'));
          this.$set(this, 'showTabNav', false);
          break;
        case '2':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList3.png'));
          this.$set(this, 'showTabNav', false);
          break;
      }
    },
    save: (0, _validate.Debounce)(function () {
      var _this6 = this;
      var data = {
        category_page_config: this.active,
        is_show_category: this.radio
      };
      (0, _systemGroup.SaveDataApi)(data, '/admin/page/layout/category/config/save').then(function (res) {
        _this6.$message.success("保存成功");
      });
    }),
    getConfig: function getConfig() {
      var _this7 = this;
      (0, _systemGroup.getDataApi)().then(function (res) {
        _this7.$set(_this7, 'active', res.categoryConfig);
        _this7.$set(_this7, 'radio', res.isShowCategory == 'true' ? true : false);
        _this7.switchTab(_this7.active);
      });
    },
    kindSave: (0, _validate.Debounce)(function () {
      var _this8 = this;
      var data = {
        key: 'homePageSaleListStyle',
        value: Number(this.active1) + 1
      };
      (0, _systemGroup.themeSave)(data).then(function (res) {
        _this8.$message.success("保存成功");
      });
    }),
    kindGet: function kindGet() {
      var _this9 = this;
      (0, _systemGroup.getTheme)({
        key: 'homePageSaleListStyle'
      }).then(function (res) {
        var conque = res - 1;
        _this9.$set(_this9, 'active1', conque.toString());
        if (res == '1') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList1.png')); //showTabNav
          _this9.$set(_this9, 'showTabNav', true);
        } else if (res == '2') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList2.png'));
          _this9.$set(_this9, 'showTabNav', false);
        } else if (res == '3') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList3.png'));
          _this9.$set(_this9, 'showTabNav', false);
        }
      });
    },
    getLink: function getLink(index) {
      this.itemIndex = index;
      this.$refs.linkaddres.dialogVisible = true;
    },
    ProductNavTab: function ProductNavTab(index) {
      this.listActive = index;
      if (index == 0) {
        this.showCurrent('home');
      } else if (index == 1) {
        this.cateNav();
      } else if (index == 2) {
        this.showCurrent('user');
      } else if (index == 3) {
        this.showCurrent('newHome');
      }
    },
    linkUrl: function linkUrl(e) {
      this.menuList[this.itemIndex].url = e;
    }
  }
};