"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  props: {
    styles: {
      type: Object,
      default: null
    },
    optionData: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      myChart: null
    };
  },
  computed: {
    echarts: function echarts() {
      return 'echarts' + Math.ceil(Math.random() * 100);
    }
  },
  watch: {
    optionData: {
      handler: function handler(newVal, oldVal) {
        this.handleSetVisitChart();
      },
      deep: true // 对象内部属性的监听，关键。
    }
  },
  mounted: function mounted() {
    var _this = this;
    var vm = this;
    vm.$nextTick(function () {
      vm.handleSetVisitChart();
      window.addEventListener('resize', _this.wsFunc);
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.wsFunc);
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  methods: {
    wsFunc: function wsFunc() {
      this.myChart.resize();
    },
    handleSetVisitChart: function handleSetVisitChart() {
      this.myChart = _echarts.default.init(document.getElementById(this.echarts));
      var option = null;
      option = this.optionData;
      // 基于准备好的dom，初始化echarts实例
      this.myChart.setOption(option, true);
    }
  }
};