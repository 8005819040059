"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _bodyClass = require("@/api/bodyClass");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'bodyClass',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1'
      },
      checkedCities: ['ID', '体质名称', '成因', '定义']
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getList: function getList() {
      var _this = this;
      (0, _bodyClass.BodyListApi)().then(function (res) {
        _this.tableData.data = res;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    }
  }
};