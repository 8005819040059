"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toDay = _interopRequireDefault(require("./components/toDay"));
var _transaction = _interopRequireDefault(require("./components/transaction"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  components: {
    toDay: _toDay.default,
    transactionFrom: _transaction.default
  }
};