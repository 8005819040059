var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "router-link",
                { attrs: { to: { path: "/content/bodyCreate" } } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("添加体质")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _vm.checkedCities.includes("体质名称")
                ? _c("el-table-column", {
                    attrs: {
                      label: "体质名称",
                      prop: "tzmc",
                      "min-width": "100",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("定义")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "dingyi",
                      label: "定义",
                      "min-width": "300",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("成因")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "chengyin",
                      label: "成因",
                      "min-width": "300",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/content/bodyCreate/" + scope.row.rowid,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("详情")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/content/bodyCreate/" + scope.row.rowid,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }