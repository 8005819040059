"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _visualization = _interopRequireDefault(require("./components/visualization"));
var _tableData = _interopRequireDefault(require("./components/tableData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    product: _visualization.default,
    tableData: _tableData.default
  }
};