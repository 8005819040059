var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "商品信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "商品详情" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "其他设置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "249",
                              placeholder: "请输入商品名称",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "storeName", $$v)
                              },
                              expression: "formValidate.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：", prop: "cateIds" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merCateList,
                              props: _vm.props2,
                              clearable: "",
                              "show-all-levels": false,
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.cateIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cateIds", $$v)
                              },
                              expression: "formValidate.cateIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品关键字：", prop: "keyword" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入商品关键字",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "keyword", $$v)
                              },
                              expression: "formValidate.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位：", prop: "unitName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入单位",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.unitName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "unitName", $$v)
                              },
                              expression: "formValidate.unitName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "mr20",
                              staticStyle: { width: "100%" },
                              attrs: { disabled: _vm.isDisabled },
                              on: { change: _vm.typeChange },
                              model: {
                                value: _vm.formValidate.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "type", $$v)
                                },
                                expression: "formValidate.type",
                              },
                            },
                            _vm._l(_vm.type, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowcardRelationId,
                              expression: "isShowcardRelationId",
                            },
                          ],
                          attrs: { label: "健康卡关联id" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入健康卡关联明道id" },
                            model: {
                              value: _vm.formValidate.cardRelationId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "cardRelationId",
                                  $$v
                                )
                              },
                              expression: "formValidate.cardRelationId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品简介：", prop: "storeInfo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "250",
                              rows: 3,
                              placeholder: "请输入商品简介",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.storeInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "storeInfo", $$v)
                              },
                              expression: "formValidate.storeInfo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面图：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "acea-row upLoadPicBox row-middle",
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title: "上传封面图不得大于5MB",
                                      closable: false,
                                      type: "warning",
                                      effect: "dark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品轮播图：",
                            prop: "sliderImages",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.sliderImages,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "true" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      !_vm.isDisabled
                                        ? _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.sliderImages.length < 10 &&
                              !_vm.isDisabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 18, sm: 18, md: 18, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主图视频：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "250",
                              disabled: _vm.isDisabled,
                              placeholder: "请输入视频链接",
                            },
                            model: {
                              value: _vm.videoLink,
                              callback: function ($$v) {
                                _vm.videoLink = $$v
                              },
                              expression: "videoLink",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 6, sm: 6, md: 6, lg: 12, xl: 12 } },
                    [
                      _vm.videoLink
                        ? _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: { click: _vm.zh_uploadFile },
                            },
                            [_vm._v("确认添加")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.videoLink
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-upload",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("3", "", "", "video")
                                },
                              },
                            },
                            [_vm._v("选择视频")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _vm.formValidate.videoLink
                      ? _c("div", { staticClass: "iview-video-style" }, [
                          _c(
                            "video",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "100% !important",
                                "border-radius": "10px",
                              },
                              attrs: {
                                src: _vm.formValidate.videoLink,
                                controls: "",
                                autoplay: "",
                                muted: "",
                              },
                              domProps: { muted: true },
                            },
                            [
                              _vm._v(
                                "\n              您的浏览器不支持 video 标签。\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mark" }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "iconv iconfont iconmd-trash",
                            on: {
                              click: function ($event) {
                                return _vm.delVideo()
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 18, sm: 18, md: 18, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "运费模板：", prop: "tempId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "mr20",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.formValidate.tempId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "tempId", $$v)
                                },
                                expression: "formValidate.tempId",
                              },
                            },
                            _vm._l(_vm.shippingList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 6, sm: 6, md: 6, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isDisabled,
                              expression: "!isDisabled",
                            },
                          ],
                          staticClass: "mr15",
                          on: { click: _vm.addTem },
                        },
                        [_vm._v("运费模板")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品规格：", props: "specType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.specType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.specType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "specType", $$v)
                                },
                                expression: "formValidate.specType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: false },
                                },
                                [_vm._v("单规格")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("多规格"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "佣金设置：", props: "isSub" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangetype(
                                    _vm.formValidate.isSub
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.isSub,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isSub", $$v)
                                },
                                expression: "formValidate.isSub",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: true },
                                },
                                [_vm._v("单独设置")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("默认设置"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.specType && !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择规格：", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.formValidate.selectRule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "selectRule",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.ruleName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [_vm._v("添加规格")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            _vm._l(
                              _vm.formValidate.attr,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("span", { staticClass: "mr5" }, [
                                        _vm._v(_vm._s(item.attrName)),
                                      ]),
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveAttr(index)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "rulesBox" },
                                    [
                                      _vm._l(
                                        item.attrValue,
                                        function (j, indexn) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: indexn,
                                              staticClass: "mb5 mr10",
                                              attrs: {
                                                closable: "",
                                                size: "medium",
                                                "disable-transitions": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.handleClose(
                                                    item.attrValue,
                                                    indexn
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(j) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      item.inputVisible
                                        ? _c("el-input", {
                                            ref: "saveTagInput",
                                            refInFor: true,
                                            staticClass: "input-new-tag",
                                            attrs: { size: "small" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.attrValue.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attrValue,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.attrValue.attrsVal",
                                            },
                                          })
                                        : _c(
                                            "el-button",
                                            {
                                              staticClass: "button-new-tag",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showInput(item)
                                                },
                                              },
                                            },
                                            [_vm._v("+ 添加")]
                                          ),
                                    ],
                                    2
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _vm.isBtn
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格值：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格值",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsVal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 12,
                                        lg: 6,
                                        md: 6,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "noLeft" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "mr15",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.createAttrName },
                                            },
                                            [_vm._v("确定")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            { on: { click: _vm.offAttrName } },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isBtn
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addBtn },
                                    },
                                    [_vm._v("添加新规格")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.attr.length > 0 &&
                  _vm.formValidate.specType &&
                  !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "批量设置：" } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1369417214
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                    min: 0,
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                        max: scope.row.price,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.brokerage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerage",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerage",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            99765219
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                        max: scope.row.price,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .brokerageTwo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerageTwo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerageTwo",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1573915567
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        "min-width": "80",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "submission",
                                            attrs: { type: "text" },
                                            on: { click: _vm.batchAdd },
                                          },
                                          [_vm._v("批量添加")]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.specType === false
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formValidate.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1357914119
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                    min: 0,
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isDisabled,
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.brokerage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerage",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerage",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3549059968
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isDisabled,
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .brokerageTwo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerageTwo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerageTwo",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            342384460
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.params.id && _vm.showAll
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "全部sku：" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "default",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAllSku()
                                    },
                                  },
                                },
                                [_vm._v("展示")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.attr.length > 0 &&
                      _vm.formValidate.specType
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              class: _vm.isDisabled
                                ? "disLabel"
                                : "disLabelmoren",
                              attrs: { label: "商品属性：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3478746955
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "一级返佣(元)",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      disabled: _vm.isDisabled,
                                                      type: "number",
                                                      min: 0,
                                                      max: scope.row.price,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerage",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerage",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2857277871
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "二级返佣(元)",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      disabled: _vm.isDisabled,
                                                      type: "number",
                                                      min: 0,
                                                      max: scope.row.price,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerageTwo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerageTwo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerageTwo",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3609981283
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isDisabled
                                    ? _c("el-table-column", {
                                        key: "3",
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "submission",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delAttrTable(
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2803824461
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && !_vm.isDisabled,
                      expression: "currentTab === 1 && !isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("Tinymce", {
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && _vm.isDisabled,
                      expression: "currentTab === 1 && isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "商品详情：" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.formValidate.content || "无"),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: "请输入排序",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "积分：" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: "请输入排序",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formValidate.giveIntegral,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "giveIntegral",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.giveIntegral",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "虚拟销量：" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: "请输入排序",
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formValidate.ficti,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "ficti", $$v)
                                  },
                                  expression: "formValidate.ficti",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品推荐：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: {
                                size: "small",
                                disabled: _vm.isDisabled,
                              },
                              on: { change: _vm.onChangeGroup },
                              model: {
                                value: _vm.checkboxGroup,
                                callback: function ($$v) {
                                  _vm.checkboxGroup = $$v
                                },
                                expression: "checkboxGroup",
                              },
                            },
                            _vm._l(_vm.recommend, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "活动优先级：" } }, [
                        _c(
                          "div",
                          { staticClass: "color-list acea-row row-middle" },
                          [
                            _vm._l(_vm.formValidate.activity, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item,
                                  staticClass: "color-item",
                                  class: _vm.activity[item],
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    draggable: "true",
                                  },
                                  on: {
                                    dragstart: function ($event) {
                                      return _vm.handleDragStart($event, item)
                                    },
                                    dragover: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleDragOver($event, item)
                                    },
                                    dragenter: function ($event) {
                                      return _vm.handleDragEnterFont(
                                        $event,
                                        item
                                      )
                                    },
                                    dragend: function ($event) {
                                      return _vm.handleDragEnd($event, item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tip" }, [
                              _vm._v("可拖动按钮调整活动的优先展示顺序"),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "proCoupon",
                          attrs: { label: "优惠券：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.coupons,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "mr10 mb10",
                                      attrs: {
                                        closable: !_vm.isDisabled,
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleCloseCoupon(tag)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(tag.name) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              !_vm.isDisabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addCoupon },
                                    },
                                    [_vm._v("选择优惠券")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab>0",
                        },
                      ],
                      staticClass: "submission priamry_border",
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 2,
                          expression: "currentTab<2",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.currentTab === 2 || _vm.$route.params.id) &&
                            !_vm.isDisabled,
                          expression:
                            "(currentTab===2 || $route.params.id) && !isDisabled",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreatTemplates", {
        ref: "addTemplates",
        on: { getList: _vm.getShippingList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }