"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _statistic = require("@/api/statistic");
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty({
      optionData: {},
      style: {
        height: "370px"
      },
      timeVal: [],
      dateLimit: "",
      list: [],
      fromList: this.$constants.timeList
    }, "dateLimit", ""), "userView", {}), "pickerOptions", this.$timeOptions);
  },
  components: {
    echartsNew: _index.default
  },
  created: function created() {
    var end = new Date();
    var start = new Date();
    start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29)));
    this.timeVal = [start, end];
  },
  mounted: function mounted() {
    this.dateLimit = "lately7";
    this.dateLimitPram = "lately7";
    this.getUserView();
    this.getChannel();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.dateLimit = e ? this.timeVal.join(",") : "";
      this.dateLimitPram = e ? this.timeVal.join(",") : "";
    },
    onSeach: function onSeach() {
      this.getUserView();
    },
    exports: function exports() {},
    selectChange: function selectChange(limit) {
      if (limit == "") {
        //昨天的时间
        this.$set(this, "dateLimitPram", "yesterday");
        this.getUserView();
      } else {
        this.dateLimitPram = limit;
        this.getUserView();
      }
    },
    //用户概览
    getUserView: function getUserView() {
      var _this = this;
      (0, _statistic.userOverviewData)({
        dateLimit: this.dateLimitPram
      }).then(function (res) {
        _this.userView = res;
      });
    },
    //渠道
    getChannel: function getChannel() {
      var _this2 = this;
      (0, _statistic.userChannelData)().then(function (res) {
        var channelData = new Array();
        channelData = [{
          name: "H5",
          value: 0,
          channel: "h5"
        }, {
          name: "APP",
          value: 0,
          channel: "ios"
        }, {
          name: "小程序",
          value: 0,
          channel: "routine"
        }, {
          name: "公众号",
          value: 0,
          channel: "wechat"
        }];
        var channelArr = new Array();
        channelData.forEach(function (item) {
          res.forEach(function (item1) {
            //通过两层遍历返回api数据的长度，并且得到指定key的渠道name
            if (item.channel == item1.channel) {
              channelArr.push({
                name: item.name,
                value: item1.num ? item1.num : 0,
                channel: item1.channel
              });
            }
          });
        });
        _this2.optionData = {
          tooltip: {
            trigger: "item"
          },
          legend: {
            orient: "vertical",
            left: "right"
          },
          series: [{
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              },
              normal: {
                color: function color(params) {
                  //自定义颜色
                  var colorList = ["#1BBE6B", "#1890FF", "#EF9C20", "#4BCAD5"];
                  return colorList[params.dataIndex];
                }
              }
            },
            labelLine: {
              show: false
            },
            data: channelArr
          }]
        };
      });
    }
  }
};