var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo",
                attrs: {
                  "default-active": _vm.activeIndex,
                  mode: "horizontal",
                },
                on: { select: _vm.handleSelect },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("基本信息"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("饮食调养"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [
                  _vm._v("穴位保健"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "4" } }, [
                  _vm._v("中药饮片"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "5" } }, [
                  _vm._v("产品推荐"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "param",
                attrs: { "label-width": "150px", model: _vm.param },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex == 1,
                        expression: "activeIndex == 1",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "体质名称",
                          prop: "tzmc",
                          rules: [
                            {
                              required: true,
                              message: "请填写体质名称",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "体质名称", maxlength: "100" },
                          model: {
                            value: _vm.param.tzmc,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "tzmc", $$v)
                            },
                            expression: "param.tzmc",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "定义",
                          prop: "dingyi",
                          rules: [
                            {
                              required: true,
                              message: "请填写定义",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "定义" },
                          model: {
                            value: _vm.param.dingyi,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "dingyi", $$v)
                            },
                            expression: "param.dingyi",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "成因",
                          prop: "chengyin",
                          rules: [
                            {
                              required: true,
                              message: "请填写成因",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "成因" },
                          model: {
                            value: _vm.param.chengyin,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "chengyin", $$v)
                            },
                            expression: "param.chengyin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "常见表现",
                          prop: "cjbx",
                          rules: [
                            {
                              required: true,
                              message: "请填写常见表现",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "常见表现" },
                          model: {
                            value: _vm.param.cjbx,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "cjbx", $$v)
                            },
                            expression: "param.cjbx",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "发病倾向",
                          prop: "fbqx",
                          rules: [
                            {
                              required: true,
                              message: "请填写发病倾向",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "发病倾向" },
                          model: {
                            value: _vm.param.fbqx,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "fbqx", $$v)
                            },
                            expression: "param.fbqx",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "体质特征",
                          prop: "tztz",
                          rules: [
                            {
                              required: true,
                              message: "请填写体质特征",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "体质特征" },
                          model: {
                            value: _vm.param.tztz,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "tztz", $$v)
                            },
                            expression: "param.tztz",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "心里特征",
                          prop: "xltz",
                          rules: [
                            {
                              required: true,
                              message: "请填写心里特征",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "心里特征" },
                          model: {
                            value: _vm.param.xltz,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "xltz", $$v)
                            },
                            expression: "param.xltz",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "对外界环境适应能力",
                          prop: "dwjhjsynl",
                          rules: [
                            {
                              required: true,
                              message: "请填写对外界环境适应能力",
                              trigger: ["blur", "change"],
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "对外界环境适应能力" },
                          model: {
                            value: _vm.param.dwjhjsynl,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "dwjhjsynl", $$v)
                            },
                            expression: "param.dwjhjsynl",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "详情介绍", prop: "xqjs" } },
                      [
                        _c("Tinymce", {
                          model: {
                            value: _vm.param.xqjs,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "xqjs", $$v)
                            },
                            expression: "param.xqjs",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex == 2,
                        expression: "activeIndex==2",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调养方案", prop: "tyfa" } },
                      [
                        _c("Tinymce", {
                          model: {
                            value: _vm.param.tyfa,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "tyfa", $$v)
                            },
                            expression: "param.tyfa",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex == 3,
                        expression: "activeIndex == 3",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "穴位保健", prop: "xwbj" } },
                      [
                        _c("Tinymce", {
                          model: {
                            value: _vm.param.xwbj,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "xwbj", $$v)
                            },
                            expression: "param.xwbj",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex == 4,
                        expression: "activeIndex == 4",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "20px 0px" },
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.clickZyypSelect()
                          },
                        },
                      },
                      [_vm._v("选择中药饮片")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.param.zyypList, border: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsName",
                            label: "基本信息",
                            width: "180",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px !important",
                                      height: "100px !important",
                                    },
                                    attrs: {
                                      src: scope.row.goodsImageUrl,
                                      "preview-src-list": [
                                        scope.row.goodsImageUrl,
                                      ],
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsDescribe",
                            label: "作用",
                            width: "360",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "90",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: { "font-size": "25px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteZyyp(scope.row.rowId)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex == 5,
                        expression: "activeIndex == 5",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "20px 0px" },
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCptjSelect()
                          },
                        },
                      },
                      [_vm._v("请选择商品")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.param.productList, border: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "storeName",
                            label: "商品名称",
                            width: "220",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "price", label: "价格", width: "100" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px !important",
                                      height: "100px !important",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "storeInfo",
                            label: "商品简介",
                            width: "360",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            width: "90",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: { "font-size": "25px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCptj(scope.row.id)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handerSubmit()
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      title: "选择中药饮片",
                      visible: _vm.zyypVisible,
                      "before-close": _vm.handleClose,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.zyypVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入饮片名称" },
                        model: {
                          value: _vm.zyypSearchKeywords,
                          callback: function ($$v) {
                            _vm.zyypSearchKeywords = $$v
                          },
                          expression: "zyypSearchKeywords",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.searchZyyp()
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.selectZyypList, border: "" },
                        on: { "selection-change": _vm.handleSelectionChange },
                      },
                      [
                        _c("el-table-column", { attrs: { type: "selection" } }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "spmc",
                            label: "基本信息",
                            width: "180",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px !important",
                                      height: "100px !important",
                                    },
                                    attrs: {
                                      src: scope.row.logo,
                                      "preview-src-list": [scope.row.logo],
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "zuoyong",
                            label: "作用",
                            width: "360",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.zyypVisible = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmZyyp()
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { title: "选择商品", visible: _vm.cptjVisible },
                    on: {
                      "update:visible": function ($event) {
                        _vm.cptjVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.cptjSearchKeywords,
                          callback: function ($$v) {
                            _vm.cptjSearchKeywords = $$v
                          },
                          expression: "cptjSearchKeywords",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.searchCptj()
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.selectCptjList, border: "" },
                        on: {
                          "selection-change": _vm.handleCptjSelectionChange,
                        },
                      },
                      [
                        _c("el-table-column", { attrs: { type: "selection" } }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "storeName",
                            label: "商品名称",
                            width: "220",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "price", label: "价格", width: "100" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px !important",
                                      height: "100px !important",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "storeInfo",
                            label: "商品简介",
                            width: "360",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.cptjVisible = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmCptj()
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }