var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: { change: _vm.seachList },
                          model: {
                            value: _vm.tableFrom.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "type", $$v)
                            },
                            expression: "tableFrom.type",
                          },
                        },
                        _vm._l(_vm.options, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.checkPermi(["admin:order:status:num"])
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "订单状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.seachList },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "all" } },
                                [
                                  _vm._v(
                                    "全部 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.all
                                          ? _vm.orderChartType.all
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "unPaid" } },
                                [
                                  _vm._v(
                                    "未支付 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.unPaid
                                          ? _vm.orderChartType.unPaid
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "notShipped" } },
                                [
                                  _vm._v(
                                    "未发货 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.notShipped
                                          ? _vm.orderChartType.notShipped
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "spike" } },
                                [
                                  _vm._v(
                                    "待收货 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.spike
                                          ? _vm.orderChartType.spike
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "bargain" } },
                                [
                                  _vm._v(
                                    "待评价 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.bargain
                                          ? _vm.orderChartType.bargain
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "complete" } },
                                [
                                  _vm._v(
                                    "交易完成 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.complete
                                          ? _vm.orderChartType.complete
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "toBeWrittenOff" } },
                                [
                                  _vm._v(
                                    "待核销 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.toBeWrittenOff
                                          ? _vm.orderChartType.toBeWrittenOff
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "refunding" } },
                                [
                                  _vm._v(
                                    "退款中 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refunding
                                          ? _vm.orderChartType.refunding
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "refunded" } },
                                [
                                  _vm._v(
                                    "已退款 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refunded
                                          ? _vm.orderChartType.refunded
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "deleted" } },
                                [
                                  _vm._v(
                                    "已删除 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.deleted
                                          ? _vm.orderChartType.deleted
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.tableFrom.dateLimit)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.dateLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                            },
                            expression: "tableFrom.dateLimit",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "订单号：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请输入订单号",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tableFrom.orderNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "orderNo", $$v)
                            },
                            expression: "tableFrom.orderNo",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: { click: _vm.seachList },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:export:excel:order"],
                              expression: "['admin:export:excel:order']",
                            },
                          ],
                          attrs: { size: "small" },
                          on: { click: _vm.exports },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt20" }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
                "row-key": function (row) {
                  return row.orderId
                },
              },
            },
            [
              _vm.checkedCities.includes("订单号")
                ? _c("el-table-column", {
                    attrs: { label: "订单号", "min-width": "210" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", {
                                staticStyle: { display: "block" },
                                domProps: {
                                  textContent: _vm._s(scope.row.orderId),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isDel,
                                      expression: "scope.row.isDel",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ED4014",
                                    display: "block",
                                  },
                                },
                                [_vm._v("用户已删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2125948946
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("订单类型")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "orderType",
                      label: "订单类型",
                      "min-width": "110",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("收货人")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "收货人",
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商品信息")
                ? _c("el-table-column", {
                    attrs: { label: "商品信息", "min-width": "400" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    trigger: "hover",
                                    placement: "right",
                                    "open-delay": 800,
                                  },
                                },
                                [
                                  scope.row.productList &&
                                  scope.row.productList.length
                                    ? _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        _vm._l(
                                          scope.row.productList,
                                          function (val, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "tabBox acea-row row-middle",
                                                staticStyle: {
                                                  "flex-wrap": "inherit",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "demo-image__preview mr10",
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      attrs: {
                                                        src: val.info.image,
                                                        "preview-src-list": [
                                                          val.info.image,
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text_overflow",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tabBox_tit mr10",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            val.info
                                                              .productName +
                                                              " | "
                                                          ) +
                                                            _vm._s(
                                                              val.info.sku
                                                                ? val.info.sku
                                                                : "-"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tabBox_pice",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            "￥" +
                                                              val.info.price
                                                              ? val.info.price +
                                                                  " x " +
                                                                  val.info
                                                                    .payNum
                                                              : "-"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.productList &&
                                  scope.row.productList.length
                                    ? _c(
                                        "div",
                                        { staticClass: "pup_card" },
                                        _vm._l(
                                          scope.row.productList,
                                          function (val, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "tabBox acea-row row-middle",
                                                staticStyle: {
                                                  "flex-wrap": "inherit",
                                                },
                                              },
                                              [
                                                _c("div", {}, [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tabBox_tit mr10",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          val.info.productName +
                                                            " | "
                                                        ) +
                                                          _vm._s(
                                                            val.info.sku
                                                              ? val.info.sku
                                                              : "-"
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tabBox_pice",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "￥" + val.info.price
                                                            ? val.info.price +
                                                                " x " +
                                                                val.info.payNum
                                                            : "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3218588117
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("实际支付")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "payPrice",
                      label: "实际支付",
                      "min-width": "80",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("支付方式")
                ? _c("el-table-column", {
                    attrs: { label: "支付方式", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.payTypeStr)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1599512067
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("订单状态")
                ? _c("el-table-column", {
                    attrs: { label: "订单状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                scope.row.refundStatus === 1 ||
                                scope.row.refundStatus === 2
                                  ? _c(
                                      "div",
                                      { staticClass: "refunding" },
                                      [
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                placement: "left",
                                                "open-delay": 500,
                                              },
                                            },
                                            [
                                              _c(
                                                "b",
                                                {
                                                  staticStyle: {
                                                    color: "#f124c7",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.statusStr.value
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pup_card flex-column",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "退款原因：" +
                                                        _vm._s(
                                                          scope.row
                                                            .refundReasonWap
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "备注说明：" +
                                                        _vm._s(
                                                          scope.row
                                                            .refundReasonWapExplain
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "退款时间：" +
                                                        _vm._s(
                                                          scope.row
                                                            .refundReasonTime
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "acea-row" },
                                                    [
                                                      _vm._v(
                                                        "\n                          退款凭证：\n                          "
                                                      ),
                                                      scope.row
                                                        .refundReasonWapImg
                                                        ? _vm._l(
                                                            scope.row.refundReasonWapImg.split(
                                                              ","
                                                            ),
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "demo-image__preview",
                                                                  staticStyle: {
                                                                    width:
                                                                      "35px",
                                                                    height:
                                                                      "auto",
                                                                    display:
                                                                      "inline-block",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      attrs: {
                                                                        src: item,
                                                                        "preview-src-list":
                                                                          [
                                                                            item,
                                                                          ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                            },
                                                            [_vm._v("无")]
                                                          ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.statusStr.value)),
                                    ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1777794670
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("下单时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "下单时间",
                      "min-width": "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid === false
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:order:update:price"],
                                    expression: "['admin:order:update:price']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "notShipped" &&
                        scope.row.refundStatus === 0
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:order:send"],
                                    expression: "['admin:order:send']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("发送货")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "toBeWrittenOff" &&
                        scope.row.paid == true &&
                        scope.row.refundStatus === 0
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:order:write:update"],
                                    expression: "['admin:order:write:update']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onWriteOff(scope.row)
                                  },
                                },
                              },
                              [_vm._v("立即核销")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["admin:order:info"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderDetails(
                                              scope.row.orderId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("订单详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:order:status:list"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderLog(
                                              scope.row.orderId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("订单记录")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:order:mark"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderMark(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("订单备注")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.refundStatus === 1 &&
                                _vm.checkPermi(["admin:order:refund:refuse"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderRefuse(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("拒绝退款")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.refundStatus === 1 &&
                                _vm.checkPermi(["admin:order:refund"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderRefund(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("立即退款")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.statusStr.key === "deleted" &&
                                _vm.checkPermi(["admin:order:delete"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除订单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.statusStr.key !== "unPaid"
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderPrint(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("打印小票")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑订单",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("zb-parser", {
                attrs: {
                  "form-id": 104,
                  "is-create": _vm.isCreate,
                  "edit-data": _vm.editData,
                },
                on: { submit: _vm.handlerSubmit, resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisibleJI,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleJI = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "oid",
                  align: "center",
                  label: "ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "changeMessage",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
      _vm._v(" "),
      _c("order-send", {
        ref: "send",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _c("order-video-send", {
        ref: "videoSend",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _vm.RefuseVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "拒绝退款原因",
                visible: _vm.RefuseVisible,
                width: "500px",
                "before-close": _vm.RefusehandleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.RefuseVisible = $event
                },
              },
            },
            [
              _c("zb-parser", {
                attrs: {
                  "form-id": 106,
                  "is-create": 1,
                  "edit-data": _vm.RefuseData,
                },
                on: {
                  submit: _vm.RefusehandlerSubmit,
                  resetForm: _vm.resetFormRefusehand,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款处理",
            visible: _vm.refundVisible,
            width: "500px",
            "before-close": _vm.refundhandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.refundVisible = $event
            },
          },
        },
        [
          _vm.refundVisible
            ? _c("zb-parser", {
                attrs: {
                  "form-id": 107,
                  "is-create": 1,
                  "edit-data": _vm.refundData,
                },
                on: {
                  submit: _vm.refundhandlerSubmit,
                  resetForm: _vm.resetFormRefundhandler,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }