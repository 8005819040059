import { render, staticRenderFns } from "./index.vue?vue&type=template&id=484f8af4&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484f8af4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/zyj-admin-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('484f8af4')) {
      api.createRecord('484f8af4', component.options)
    } else {
      api.reload('484f8af4', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=484f8af4&scoped=true", function () {
      api.rerender('484f8af4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/financial/record/index.vue"
export default component.exports